<template>
  <el-dialog
    v-model="isShown"
    :style="screenWidth >= 768 ? 'width: 33%' : screenWidth === 768 ? 'width: 50%' : 'width: 80%'"
    align-center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #header>
      <div class="dialog-title">{{ questionnaireDialog.title }}</div>
    </template>
    <div class="dialog-image-container">
      <img src="../assets/images/questionnaire.png" :style="screenWidth > 768 ? 'width: 8rem' : 'width: 6rem'" />
    </div>
    <div class="dialog-description">{{ questionnaireDialog.description }}</div>

    <template #footer>
      <div class="flex mx-auto" :class="screenWidth > 768 ? 'flex-row' : 'flex-col'">
        <el-button type="danger" @click="navigateTo('iSART-evaluation')" :style="screenWidth > 768 ? 'width:48%' : 'width:100%; margin-bottom:8px'">
          {{ questionnaireDialog.primaryButton }}
        </el-button>
        <el-button @click="navigateTo('login')" :style="screenWidth > 768 ? 'width:48%' : 'width:100%; margin-left:auto'">{{
          questionnaireDialog.secondaryButton
        }}</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import { ref, toRefs } from '@vue/reactivity';
import { defineProps, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  questionnaireDialogVisible: {
    type: Boolean,
    default: false,
  },
});
const { questionnaireDialogVisible } = toRefs(props);
let isShown = ref(false);
const storage = localStorage;

watch(questionnaireDialogVisible, () => {
  isShown.value = !isShown.value;
});

const questionnaireDialog = {
  title: 'Help us improve',
  description: 'Before you exit consider filling in the questionnaire to help us improve this program to fit your needs. It only takes 5 mins!',
  primaryButton: 'Yes, sure!',
  secondaryButton: 'Maybe later',
};

const router = useRouter();

let screenWidth = ref('');
onMounted(() => {
  screenWidth.value = document.body.clientWidth;
  window.onresize = () => {
    return (() => {
      screenWidth.value = document.body.clientWidth;
    })();
  };
});

const navigateTo = (param) => {
  router.push({ name: param });
  if (param === 'login') {
    storage.clear();
  } else {
    isShown.value = false;
  }
};
</script>
