<template>
  <div class="fixed bottom-5 right-5 z-50">
    <el-row class="justify-end">
      <el-col :span="4">
        <img src="../assets/images/chatbot.png" class="cursor-pointer" @click="toggleChat" :class="{ 'is-open': isChatOpen }" />
      </el-col>
    </el-row>

    <div v-if="isChatOpen" class="fixed bottom-[110px] right-6 bg-slate-50 rounded-lg flex flex-col">
      <div class="bg-[#db2777] p-3 flex justify-between items-center rounded-t-lg text-white">
        <h3>i-SART Expert 2.0</h3>
        <button @click="toggleChat" class="bg-[#db2777] border-none text-lg cursor-pointer">×</button>
      </div>

      <div class="grow overflow-y-auto p-5 w-[280px] text-[#606266]">
        <div class="mb-2">How can I help you today?</div>
        <div class="mb-2">
          Paste any Failure Mode from i-SART and let's explain this FM further and chat about mitigation strategies and real incidents
          <span class="cursor-pointer underline underline-offset-2 text-[#db2777]" @click="redirectToChatgpt">here</span>!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isChatOpen: false,
    };
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    redirectToChatgpt() {
      window.open('https://chatgpt.com/g/g-kUbYPOMzA-isart-expert-2-0');
    },
  },
};
</script>
