<template>
  <div>
    <el-card class="mb-4">
      <el-row class="pt-4 px-8 items-center justify-between">
        <el-col :span="4">
          <img class="" src="../../assets/images/clipboard.png" />
        </el-col>
        <el-col :span="18">
          <div class="font-medium text-3xl mb-4 text-[#db2777]" v-html="main.title"></div>
          <div class="font-light text-base mb-4" v-html="main.text"></div>
        </el-col>
      </el-row>
    </el-card>
    <iframe
      width="640px"
      height="480px"
      src="https://forms.office.com/Pages/ResponsePage.aspx?id=7kOJDHDDs0u6UTIfQG8y7O_xXTS9DGJLoH0hJdoyXE5UNVQzWDhCRkZJTjRTMko3NFYwWFRGS1gyMC4u&embed=true"
      frameborder="0"
      marginwidth="0"
      marginheight="0"
      style="border: none; max-width: 100%; max-height: 100vh; margin: auto"
      allowfullscreen
      webkitallowfullscreen
      mozallowfullscreen
      msallowfullscreen
    >
    </iframe>
  </div>
</template>

<script setup>
const main = {
  title: 'We appreciate your feedback!',
  text: `Thanks for visiting i-SART! i-SART is continuously evolving to address potential risks in the radiotherapy process through a proactive risk management approach. <br>
        This pilot version is critical for evaluating its usability and effectiveness. <br>
        Your feedback is invaluable and will guide further improvements, aiming to make i-SART an essential tool for professionals in the field and enhance patient safety in RT worldwide.
`,
  button: 'Take the survey',
  questionnaireUrl: 'https://forms.office.com/e/n2fTBh6ucB',
};
</script>
