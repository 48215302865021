<template>
  <div>
    <el-card>
      <el-row class="py-4 px-8 items-center justify-between">
        <el-col :span="4">
          <img src="../../assets/images/lungs.png" />
        </el-col>
        <el-col :span="18">
          <div :class="`font-medium text-3xl mb-4 text-[${palette.default}]`" v-html="main.title"></div>
          <div class="font-light text-base" v-html="main.text"></div>
        </el-col>
      </el-row>
    </el-card>

    <div class="mt-4">
      <el-collapse>
        <!-- FMEA Overview -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-outdent" class="mr-6" />FMEA Overview</template>
          <!-- What is FMEA? -->
          <TextBox :section="whatIsFmea" />

          <!-- Why is FMEA Important in Radiotherapy? -->
          <TextBox :section="whyFmeaImportant" />

          <!-- When to Conduct an FMEA? -->
          <UnorderedList :section="whenToConductFeam" />

          <!-- Key Steps in Conducting an FMEA -->
          <OrderedList :section="fmeaConductionKeySteps" />
          <div class="mt-4">The following template is used during an FMEA study to systematically record and analyze potential failure modes:</div>
          <table class="border-collapse border border-slate-700 mt-3 mb-6">
            <thead>
              <tr>
                <th class="px-2 py-2 text-left" colspan="2">FMEA Template</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(row, index) in fmeaProcessTable.content" :key="index">
                <tr>
                  <template v-for="(cell, index) in row" :key="index">
                    <td class="border border-slate-700 py-4 px-2 text-center">{{ cell }}</td>
                  </template>
                </tr>
              </template>
              <tr>
                <td class="px-2 py-2 text-left" colspan="2">FM: Failure mode</td>
              </tr>
              <tr>
                <td class="border border-slate-700 px-2 py-2 text-left" colspan="10">
                  <span class="italic"
                    >Huq MS, Fraass BA, Dunscombe PB, Gibbons JP Jr, Ibbott GS, Mundt AJ, Mutic S, Palta JR, Rath F, Thomadsen BR, Williamson JF,
                    Yorke ED. The report of Task Group 100 of the AAPM:
                  </span>
                  <a :class="`cursor-pointer underline underline-offset-2 text-[${palette.lightgray}]`" href="http://doi.wiley.com/10.1118/1.4947547"
                    >Application of risk analysis methods to radiation therapy quality management. Med Phys. 2016 Jul;43(7):4209. doi:
                    10.1118/1.4947547. PMID: 27370140; PMCID: PMC4985013</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
          <el-divider />

          <!-- Variations of the typical FMEA -->
          <UnorderedList :section="feamVariations" />

          <!-- Callout -->
          <div :class="`my-3 text-center text-xl font-medium text-[${palette.default}]`">
            For more information about FMEA and other proactive risk management methods, <br />please start a discussion with the “i-SART expert 2.0”
            chatbot.
          </div>
        </el-collapse-item>

        <!-- What is i-SART? -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-magnifying-glass" class="mr-6" />What is i-SART?</template>
          <!-- What is i-SART -->
          <TextBox :section="whatIsIsart" />

          <!-- Features -->
          <div :class="`text-[${palette.default}] font-bold text-2xl mb-2`">Features</div>
          <ul class="list-none pl-6">
            <li class="py-1">
              <span class="pr-2">1.</span><span class="font-semibold">Failure Modes Database</span>: Central to i-SART is a database that, in this
              pilot phase, gathers 419 FMs from eight published articles and two unpublished FMEA reports. These sources can be accessed
              <a
                :class="`cursor-pointer underline underline-offset-2 font-semibold text-[${palette.lightgray}]`"
                @click="router.push({ name: 'fm-list' })"
                >here</a
              >.
            </li>
            <li class="py-1">
              <span class="pr-2">2.</span><span class="font-semibold">Report New Failure Modes</span>: Opportunity for the users to enter Failure
              modes to inform the community of potential risks.
            </li>
            <li class="py-1">
              <span class="pr-2">3.</span><span class="font-semibold">i-SART Evaluation</span>: A survey for the users to evaluate and help improve
              i-SART in its pilot phase.
            </li>
          </ul>

          <!-- Important Note -->
          <el-divider>
            <p :class="`text-[${palette.default}] text-lg font-semibold`">IMPORTANT NOTE: This is a pilot version</p>
          </el-divider>
          <div class="mb-9">
            The FMs database should be used thoroughly as an advisory tool and not as a certified product. Every department functions under its own
            workflow, protocols, and safety barriers and technology that lead every risk assessment study.
          </div>

          <!-- Acknowledgement -->
          <div :class="`my-3 text-center text-xl font-medium text-[${palette.default}]`">
            We would like to acknowledge the contribution of European departments that shared their reports and thank them for their invaluable input.
          </div>
        </el-collapse-item>

        <!-- Failure Modes Database -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-toggle-on" class="mr-5" />Failure Modes Database</template>
          <!-- How to Use the Failure Modes Database -->
          <!-- Key Features -->
          <UnorderedList :section="keyFeatures" />

          <!-- Navigation -->
          <div class="text-xl font-semibold my-3">Navigation</div>
          <ul class="list-none pl-6">
            <li class="py-1">
              <span class="pr-2">●</span><span class="font-semibold">Process Steps</span>: Navigate through the radiotherapy
              <a
                :class="`cursor-pointer underline underline-offset-2 font-semibold text-[${palette.lightgray}]`"
                @click="router.push({ name: 'fm-list' })"
                >Process Steps</a
              >
              or use the
              <a
                :class="`cursor-pointer underline underline-offset-2 font-semibold text-[${palette.lightgray}]`"
                @click="router.push({ name: 'fm-detail' })"
                >Failure Mode List</a
              >
              in the <span :class="`font-semibold text-[${palette.lightgray}]`">Failure Modes Database</span> to explore potential FMs, their causes,
              effects, and indicative severity.
            </li>
            <li class="py-1">
              <span class="pr-2">●</span><span class="font-semibold">The process map</span>: is outlined in the top blue ribbon, and the special
              procedure of MRI-guided Adaptive Radiotherapy (MRgART) is mapped separately, Fig.1.
              <div class="m-3">
                <img src="../../assets/images/failure-modes-database-overview.png" />
              </div>
              <div class="border-2 border-neutral-500 p-2 mx-3">
                Fig. 1 Failure Modes Database (left hand-side) provides: a) Process Steps and b) Failure modes list.
              </div>
            </li>
            <li class="py-1">
              <span class="pr-2">●</span><span class="font-semibold">Sub-process Selection</span>: Select a sub-process, e.g., "Patient Assessment",
              to view associated steps.
            </li>
            <li class="py-1">
              <span class="pr-2">●</span><span class="font-semibold">Detailed Failure Modes</span>: Choose a specific step, e.g., "Patient
              Identification", to see a list of potential FMs, their severity, causes, and effects
            </li>
            <li class="py-1">
              <span class="pr-2">●</span><span class="font-semibold">Filter and Search</span>: Filter your search by techniques and severity or use
              the search box to refine your findings, Fig.2.
              <div class="m-3">
                <img src="../../assets/images/failure-modes-list-severity-techniques.png" />
              </div>
              <div class="border-2 border-neutral-500 p-2 mx-3">Fig. 2 Use search box, severity and techniques to filter your search.</div>
            </li>
          </ul>
          <el-divider />

          <!-- How to Use the Chatbot 'i-SART Expert 2.0' -->
          <!-- Access and Interaction -->
          <UnorderedList :section="accessAndInteraction" />

          <!-- Engaging with the Chatbot -->
          <UnorderedList :section="chatbotEngage" />

          <!-- Use for Continuous Learning -->
          <TextBox :section="continuousLearning" />

          <!-- Understand the Limitations -->
          <TextBox :section="limitations" />

          <!-- Sources -->
          <el-divider>
            <div :class="`text-[${palette.default}] text-lg font-semibold`" id="sources">Sources</div>
          </el-divider>
          <div class="mb-9">
            <div v-for="(technique, index) in sources" :key="index">
              <p class="font-semibold mt-2">{{ technique.name }}</p>
              <ul class="list-none pl-6">
                <div v-for="(reference, index) in technique.references" :key="index">
                  <li class="py-1">
                    <span class="pr-2">●</span>{{ reference.title }}
                    <div v-if="reference.url.length">
                      Available from:
                      <a :class="`cursor-pointer underline underline-offset-2 text-[${palette.lightgray}]`" @click="navigateTo(reference.url)">{{
                        reference.url
                      }}</a
                      >.
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </el-collapse-item>

        <!-- Report New FMs -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-book-bookmark" class="mr-6" />Report New Failure Modes</template>
          <TextBox :section="reportNewFms" />
          <div class="mt-3">
            <img src="../../assets/images/report_new_failure_modes.png" />
          </div>
        </el-collapse-item>

        <!-- i-SART Evaluation -->
        <el-collapse-item>
          <template #title><font-awesome-icon icon="fa-comments" class="mr-5" />i-SART Evaluation</template>
          <TextBox :section="isartEvaluation" />
          <div class="mt-3">
            <img src="../../assets/images/isart-evaluation.png" />
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import TextBox from '@/components/TextBox.vue';
import UnorderedList from '@/components/UnorderedList.vue';
import OrderedList from '@/components/OrderedList.vue';

const router = useRouter();

const main = {
  title: 'Welcome to iSART, <br> An Intelligent Safety Assistant tool for Radiotherapy',
  text: `iSART is a tool that aims at promoting patient safety in radiotherapy through a proactive risk management method called FMEA.`,
};

// Feam Overview
const whatIsFmea = ref({
  title: 'What is FMEA? (Failure Modes and Effects Analysis)',
  subtitle: '',
  main: [
    `Failure Mode and Effects Analysis (FMEA) is a systematic approach designed to identify, assess, and mitigate potential failures in
            processes, products, or systems before errors occur. Originally developed by the U.S. military during the 1940s, FMEA has been extensively
            refined and utilized within industries like aerospace and automotive to enhance product/process safety and reliability. Its principles
            have since been adapted across various sectors, including healthcare, proving particularly effective in complex systems like radiotherapy.`,
  ],
  callout: '',
  dividerFlag: true,
});

const whyFmeaImportant = ref({
  title: 'Why is FMEA Important in Radiotherapy?',
  subtitle: '',
  main: [
    `In radiotherapy, where precision is crucial, proactive risk management is essential. FMEA helps healthcare professionals proactively
            identify and address potential risks, thereby reducing potential errors.`,
  ],
  callout: '',
  dividerFlag: true,
});

const whenToConductFeam = ref({
  title: 'When to Conduct an FMEA?',
  description: 'Implementing FMEA at critical times ensures that the therapy process remains safe and effective. This includes:',
  subtitle: '',
  content: [
    {
      main: 'During the introduction of new techniques and technologies.',
    },
    {
      main: 'Following significant procedural changes.',
    },
    {
      main: 'As part of regular safety audits.',
    },
  ],
  subContent: [],
  dividerFlag: true,
});

const feamVariations = ref({
  title: 'Variations of the typical FMEA',
  description: '',
  subtitle: '',
  content: [
    {
      title: 'FMECA (Failure Mode, Effects, and Criticality Analysis)',
      main: `Adds a criticality analysis to prioritize failure modes based on their impact.`,
    },
    {
      title: 'HFMEA (Healthcare Failure Mode and Effect Analysis)',
      main: `Tailored specifically for healthcare, focusing on patient safety.`,
    },
  ],
  subContent: [],
  dividerFlag: true,
});

const fmeaConductionKeySteps = ref({
  title: 'Key Steps in Conducting an FMEA',
  description: '',
  subtitle: '',
  content: [
    {
      title: 'Define the Process',
      main: `Identify whether the focus is on the entire
              radiotherapy pathway or a specific subprocess like treatment planning or delivery.`,
    },
    {
      title: 'Assemble a Team',
      main: `Bring together a cross-functional team that includes all
              professional groups involved in the process.`,
    },
    {
      title: 'Map the Process',
      main: `Create a detailed map that outlines each subprocess and
              step in chronological order.`,
    },
    {
      title: `Conduct Hazard Analysis`,
      main: '',
      subContent: [
        `Identify potential failure modes (ways that something can go wrong) along with their causes and effects.`,
        `Assess the likelihood of occurrence, the chance of non-detection (Detectability), and the severity (S) of
              impact on patient safety.`,
        `Use these assessments to calculate the Risk Priority Number (RPN) or apply a risk matrix to prioritize
              high-risk failure modes.`,
      ],
    },
    {
      title: 'Implement Mitigation Strategies',
      main: `Develop strategies to reduce or
              eliminate risks and implement them effectively.`,
    },
    {
      title: 'Evaluate Effectiveness',
      main: `Regularly review the effectiveness of mitigation
              strategies and adjust as necessary.`,
    },
  ],
  subContent: [],
  dividerFlag: false,
});

const fmeaProcessTable = {
  content: [
    [
      'Process Step',
      'Potential FM',
      'Potential cause of FM',
      'Effects of potential FM',
      'Current controls',
      'Occurrence - Cause',
      'Detectability of FM',
      'Severity of effect from FM',
      'RPN',
      'Mitigation strategies',
    ],
    ['', '', '', '', '', '', '', '', '', ''],
    ['', '', '', '', '', '', '', '', '', ''],
  ],
};

// What is i-SART?
const whatIsIsart = ref({
  title: '',
  subtitle: '',
  main: [
    `<span class="font-semibold">i-SART (Intelligent Safety Assistant in Radiotherapy)</span> is a collaborative initiative between the
            University of West Attica in Greece and Vrije Universiteit Amsterdam in the Netherlands that was developed as part of postgraduate
            research.`,
    `i-SART is an interactive web application designed to enhance patient safety by promoting knowledge sharing about potential failure modes
            (FMs) in the radiotherapy (RT) process. i-SART is continuously evolving to address potential risks in the RT process through a proactive
            risk management approach.`,
  ],
  callout: '',
  dividerFlag: false,
});

// Failure Modes Database
const keyFeatures = ref({
  title: `How to Use the 'Failure Modes Database'?`,
  description: '',
  subtitle: 'Key Feature',
  content: [
    {
      title: 'Comprehensive Descriptions',
      main: `Provides detailed descriptions of potential
              FMs, their causes, effects, and severity as identified in the relevant sources.`,
    },
    {
      title: 'Integrated Chatbot',
      main: `The "i-SART expert 2.0" chatbot facilitates
              discussions on FMs, their risk mitigation strategies and real incidents, drawing from guidelines, the latest literature, and incident
              reports.`,
    },
  ],
  subContent: [],
  dividerFlag: false,
});

const accessAndInteraction = ref({
  title: `How to Use the Chatbot 'i-SART Expert 2.0'`,
  description: '',
  subtitle: 'Access and Interaction',
  content: [
    {
      title: 'Access the Chatbot',
      main: `Click on the 'i-SART Expert 2.0'.`,
      subContent: [{ main: 'Sign in or log in to the OpenAI ChatGPT (free account).' }],
    },
    {
      title: 'Start a Discussion',
      main: `<span class="underline underline-offset-2">Copy and paste a Failure Mode (FM) from the database</span> into the chat to start a
              discussion. Ask specific questions and explanations about the FM you have copied-pasted, mitigation strategies, historical incidents, or
              any other information.`,
    },
    {
      title: 'Language Flexibility',
      main: `For non-English speakers, try using the i-SART
              Expert 2.0 in your native language to facilitate better understanding.`,
    },
  ],
  subContent: [],
  dividerFlag: false,
});

const chatbotEngage = ref({
  title: '',
  description: '',
  subtitle: 'Engaging with the Chatbot',
  content: [
    {
      title: 'Ask Specific Questions',
      main: `Pose detailed questions related to potential
              risks, mitigation strategies, or past incidents relevant to the FM you are discussing.`,
    },
    {
      title: 'Provide Detailed Information',
      main: `When entering an FM, include as much detail
              as possible about the sub-process or steps involved. This specificity helps the i-SART Expert 2.0 deliver more accurate and relevant
              analysis.`,
    },
    {
      title: 'Inquire About Historical Incidents',
      main: `To obtain real or historical incidents
              related to a specific FM from the chatbot's extensive knowledge base, follow these steps:`,
      subContent: [
        {
          title: 'Specify the Failure Mode',
          main: `Clearly identify the specific FM you are
                discussing.`,
        },
        {
          title: 'Request Real Data',
          main: `Explicitly request real or historical incidents to
                ensure the chatbot sources accurate information.`,
        },
        {
          title: 'Utilize the Knowledge Base',
          main: `Encourage the chatbot to pull data from its
                comprehensive knowledge base, which includes incident reports from SAFRON (IAEA); RO-ILS (USA), UKHSA (UK); ASN (France); ARPANSA
                (Australia); and NSIR-RT (Canada).`,
        },
      ],
    },
    {
      title: 'Example Query',
      main: `"Can you provide real incidents
              <span class="font-semibold">from your knowledge base</span> related to <span class="font-semibold">[specify FM]</span> in radiotherapy?"`,
    },
  ],
  subContent: [],
  dividerFlag: false,
});

const continuousLearning = ref({
  title: '',
  subtitle: 'Use for Continuous Learning',
  main: [
    ` i-SART Expert 2.0 can also serve as an educational tool. Ask exploratory questions to deepen your understanding of FMEA methodology and
            risk management in radiotherapy.`,
  ],
  callout: '',
  dividerFlag: false,
});

const limitations = ref({
  title: '',
  subtitle: 'Understand the Limitations',
  main: [
    `While iSART Expert 2.0 is a powerful tool, remember that it operates within the scope of the knowledge base. Always review responses
            critically, especially in complex or novel scenarios.`,
  ],
  callout: '',
  dividerFlag: false,
});

const sources = [
  {
    name: 'IMRT',
    references: [
      {
        title: `Huq MS, Fraass BA, Dunscombe PB, Gibbons JP Jr, Ibbott GS, Mundt AJ, Mutic S, Palta JR, Rath F, Thomadsen BR,
              Williamson JF, Yorke ED. The report of Task Group 100 of the AAPM: Application of risk analysis methods to radiation therapy quality`,
        url: '',
      },
    ],
  },
  {
    name: 'RT pathway',
    references: [
      {
        title: `Pobbiati C, Monturano M, Vavassori A, Gerardi M, Fiore MS, Rondi E, et al. Clinical risk analysis of the
              patient's path in an Advanced Radiotherapy Center (A.R.C.) through F.M.E.A. method. J Biomed Pract [Internet]. 2019 Jun 18 [cited 2020
              Jun 8];3(1):38-69.`,
        url: 'https://www.ojs.unito.it/index.php/jbp/article/view/3376',
      },
    ],
  },
  {
    name: '3D RT',
    references: [
      {
        title: `Gehad Sobhy Muhammad, Iman Aly Sharawy, Hatem Mohamed Abdalla, Khalid Nagib Abdel Hakim and Ghada Refaat
              Meckawy (2021); FAILURE MODE AND EFFECT ANALYSIS FOR THREE DIMENSIONAL RADIOTHERAPY AT AIN SHAMS UNIVERSITY HOSPITAL Int. J. of Adv.
              Res. 9 (Dec). 971-983] (ISSN 2320-5407). DOI: 10.21474/IJAR01/13997.`,
        url: '',
      },
    ],
  },
  {
    name: 'SRS/SRT',
    references: [
      {
        title: `Sarchosoglou AA, Papavasileiou P, Bakas A, Stasinou D, Pappas E. Failure modes in stereotactic radiosurgery.
              A narrative review. Radiography (Lond). 2022 Nov;28(4):999-1009. doi: 10.1016/j.radi.2022.07.007. Epub 2022 Jul 31. PMID: 35921732.`,
        url: '',
      },
    ],
  },
  {
    name: 'SGRT DIBH',
    references: [
      {
        title: `Bright, M., Foster, R.D., Hampton, C.J., Ruiz, J., & Moeller, B. (2022). Failure modes and effects analysis
              for surface-guided DIBH breast radiotherapy. Journal of Applied Clinical Medical Physics, 23.`,
        url: 'https://doi.org/10.1002/acm2.13541',
      },
    ],
  },
  {
    name: 'VMAT',
    references: [
      {
        title: `Gilmore MDF, Rowbottom CG. Evaluation of failure modes and effect analysis for routine risk assessment of
              lung radiotherapy at a UK center. J Appl Clin Med Phys. 2021 May;22(5):36-47. doi: 10.1002/acm2.13238. Epub 2021 Apr 9. PMID: 33835698;
              PMCID: PMC8130239.`,
        url: '',
      },
    ],
  },
  {
    name: 'MRgART',
    references: [
      {
        title: `Klüter, Sebastian & Schrenk, Oliver & Renkamp, C. Katharina & Gliessmann, Stefan & Kress, Melanie & Debus,
              Jürgen & Hörner-Rieber, Juliane. (2021). A practical implementation of risk management for the clinical introduction of online adaptive
              Magnetic Resonance-guided radiotherapy. Physics and Imaging in Radiation Oncology. 17. 53-57. 10.1016/j.phro.2020.12.005.`,
        url: '',
      },
      {
        title: `Liang J, Scripes PG, Tyagi N, Subashi E, Wunner T, Cote N, Chan CY, Ng A, Brennan V, Zakeri K, Wildberger C,
              Mechalakos J. Risk analysis of the Unity 1.5 T MR-Linac adapt-to-position workflow. J Appl Clin Med Phys. 2023 Mar;24(3):e13850. doi:
              10.1002/acm2.13850. Epub 2022 Nov 21. PMID: 36411990; PMCID: PMC10018675.`,
        url: '',
      },
    ],
  },
];

// Report New FMs
const reportNewFms = ref({
  title: '',
  subtitle: '',
  main: [
    `i-SART promotes knowledge sharing among professionals in the field to disseminate potential risks in a timely and efficient manner.
            Please, consider entering any Failure Modes (ways that something can go wrong) you have encountered in your daily practice. You can help
            others prevent errors from occurring! Click on the '<span class="font-semibold">Report a New Failure Mode</span>' button on your left-hand
            side to start entering information.`,
    `We do not request any details that could reveal personal identities or specific hospital locations. Your entry will be reviewed by
            administrators for clarity before being added to the '<span class="font-semibold">Failure Modes Database</span>' to benefit the community.`,
  ],
  callout: 'Your input is highly valued and can help prevent incidents worldwide!',
  dividerFlag: false,
});

// i-SART Evaluation
const isartEvaluation = ref({
  title: '',
  subtitle: '',
  main: [`i-SART is continuously evolving to address potential risks in the RT process through a proactive risk management approach.`],
  callout: `Please, consider evaluating i-SART after use. Take a few minutes to share your insights, experience, and beliefs about i-SART. Your
            opinion matters in helping build a tool that could enhance patient safety worldwide!`,
  dividerFlag: false,
});

const palette = {
  default: '#db2777',
  lightgray: '#475569',
  low: '#B4FF9F',
  moderate: '#F9FFA4',
  severe: '#FFD59E',
  catastrophic: '#FFA1A1',
  other: '#F0F0F0',
};

const navigateTo = (url) => {
  window.open(url, '_blank').focus();
};
</script>

<style scoped>
:deep(.el-collapse-item__header) {
  @apply px-8 text-xl font-medium;
}

:deep(.el-collapse-item__content) {
  @apply px-16 text-base font-light pt-4;
}

:deep(.el-collapse-item__header) {
  @apply text-[#475569];
}

:deep(.el-divider--horizontal) {
  @apply my-10;
}
</style>
